.table-scrollable {
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
}

.table-scrollable-full {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
}

.table-info {
  background-color: #D8C6FF;
}

.table-warning {
  background-color: #FFF1DD;
}

.table-success {
  background-color: #78FACD;
}